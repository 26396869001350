export const spaces = {
  // xs
  // sm
  // default
  // md
  // lg
  // xlg
};

export const fontSize = {
  xs: `1.2rem`, // 12px
  sm: `1.6rem`, // 15px
  default: `1.8rem`, // 18px
  md: `2.6rem`, // 26px
  lg: `5.2rem`, // 52px
  xlg: `6.8rem`, // 68px
};

export const colors = {
  primary: "#FFBB00",
};

export const transitions = {
  default: `0.2s`,
};

export const largContainer = {
  larg: "725px",
  comp: window.innerHeight - 60, //50px é a altura do menu
  compProd: window.innerHeight - 120,
};

export const AltMenu = "60px";

export const TextoNegrito = 'as="b" mt="4" ml="2" fontWeight={700}';
