import React from "react";

export const SIContext = React.createContext();

export const SIDataContext = ({ children }) => {
  const [productsCart, setProductsCart] = React.useState([]);
  const [valorTaxaEntrega, setvalorTaxaEntrega] = React.useState(0);
  const [nomeEmpresa, setNomeEmpresa] = React.useState(null);
  const [enrecoEmpresa, setenrecoEmpresa] = React.useState(null);
  const [idInternoEmpresa, setidInternoEmpresa] = React.useState(null);
  const [valorMinimoDel, setvalorMinimoDel] = React.useState(null);
  const [exibeMenu, setexibeMenu] = React.useState(true);
  const [corPrimary, setcorPrimary] = React.useState("#FCBF14");
  const [corSecundary, setcorSecundary] = React.useState("#FCBF14");
  const [pintaMenu, setpintaMenu] = React.useState("inicio"); //inicio - promocao - pedido - opcoes

  React.useEffect(() => {
    if (nomeEmpresa) {
      const value = localStorage.getItem("@emp:" + nomeEmpresa + "CARRINHO");
      if (value) {
        setProductsCart(JSON.parse(value));
      }
    }
    localStorage.setItem("chakra-ui-color-mode", "light");
  }, [nomeEmpresa]);

  function addProducToCart(objeto) {
    const copyProductsCart = [...productsCart];
    copyProductsCart.push(objeto);
    setProductsCart(copyProductsCart);
    if (nomeEmpresa) {
      localStorage.setItem(
        "@emp:" + nomeEmpresa + "CARRINHO",
        JSON.stringify(copyProductsCart),
      );
    }
  }

  function removeProductToCart(id) {
    const auterado = [];
    for (var i = 0; i < productsCart.length; i++) {
      if (i !== id) {
        auterado.push(productsCart[i]);
      }
      setProductsCart(auterado);
      if (nomeEmpresa) {
        localStorage.setItem(
          "@emp:" + nomeEmpresa + "CARRINHO",
          JSON.stringify(auterado),
        );
      }
    }
  }

  function clearCart() {
    setProductsCart([]);
    localStorage.removeItem("@emp:" + nomeEmpresa + "CARRINHO");
  }

  return (
    <SIContext.Provider
      value={{
        nomeEmpresa,
        setNomeEmpresa,
        productsCart,
        addProducToCart,
        removeProductToCart,
        clearCart,
        exibeMenu,
        setexibeMenu,
        corPrimary,
        setcorPrimary,
        corSecundary,
        setcorSecundary,
        pintaMenu,
        setpintaMenu,
        idInternoEmpresa,
        setidInternoEmpresa,
        valorTaxaEntrega,
        setvalorTaxaEntrega,
        enrecoEmpresa,
        setenrecoEmpresa,
        setProductsCart,
        valorMinimoDel,
        setvalorMinimoDel
      }}
    >
      {children}
    </SIContext.Provider>
  );
};

//   https://www.youtube.com/watch?v=HqwlxJopMXw
