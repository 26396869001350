import React from "react";
import { Link, useLocation } from "react-router-dom";

import { Button, Text, Center, Flex } from "@chakra-ui/react";
import { SIContext } from "../../Context/SIContext";

export function LinkButton({ title, link, colorB, Icon }) {
  const { pintaMenu } = React.useContext(SIContext);

  return (
    <Link to={link}>
      <Center>
        <Button
          colorScheme="black"
          _hover={{
            transition: "transform .2s",
            transform: "scale(1.2)",
            boxShadow: "lg",
          }}
        >
          <Flex
            direction="column"
            align="center"
            gap="1"
            color={
              pintaMenu.toUpperCase() === title.toUpperCase() ? "white" : colorB
            }
            _hover={{ color: "white", stroke: "white" }}
          >
            <Icon
              size={pintaMenu.toUpperCase() === title.toUpperCase() ? 26 : 20}
              color="currentColor"
            />
            <Text
              fontSize="11px"
              color="currentColor"
              _hover={{ color: "white" }}
            >
              {title}
            </Text>
          </Flex>
        </Button>
      </Center>
    </Link>
  );
}

export default LinkButton;
