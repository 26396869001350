import React from "react";
import { Spinner, Flex, Image, Container } from "@chakra-ui/react";
import "./Loading.css";

import SiLogo from "../../assets/svg/siLogo.svg";
import { largContainer } from "../../styles/constants";
import { SIContext } from "../../Context/SIContext";
// import { Container } from './styles';

function Loading() {
  const { setexibeMenu } = React.useContext(SIContext);

  React.useEffect(() => {
    setexibeMenu(false);
  }, []);

  return (
    <Container maxW={largContainer.larg} minH="100vh" bg="white">
      <Flex justify="center" pt={{ base: "40vh" }}>
        <Image
          className="rotate-scale-down"
          src={SiLogo}
          maxW={{ base: "40%", md: "30%" }}
        />
      </Flex>
    </Container>
  );
}

export default Loading;
