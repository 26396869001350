import React from "react";
import axios from "axios";
import { Spinner, Flex, Image, Container, Box, Text } from "@chakra-ui/react";

import SiLogo from "../../assets/svg/siLogo.svg";
import { AltMenu, largContainer } from "../../styles/constants";
import { SIContext } from "../../Context/SIContext";
// import { Container } from './styles';
import { useParams, useNavigate, Link } from "react-router-dom";
import LinkButton from "../LinkButton";

import { SiHomeassistantcommunitystore } from "react-icons/si";
import { RiWaterPercentFill } from "react-icons/ri";
import { FaClipboardList } from "react-icons/fa";
import { IoMenu } from "react-icons/io5";
import { HiMiniShoppingCart } from "react-icons/hi2";
import { FaShoppingCart } from "react-icons/fa";

function MenuBar() {
  const { exibeMenu, corSecundary, corPrimary, nomeEmpresa, productsCart } =
    React.useContext(SIContext);

  if (!exibeMenu) return <></>;

  return (
    <Container
      position="relative"
      maxW={largContainer.larg}
      p="0"
      align="center"
      h={AltMenu}
      bg={corPrimary}
      bottom="0"
    >
      <Flex justify="space-around" pt="10px">
        <LinkButton
          title="Inicio"
          link={`/${nomeEmpresa}`}
          colorB={corSecundary}
          Icon={SiHomeassistantcommunitystore}
        />
        <LinkButton
          title="Promoções"
          link={`/${nomeEmpresa}/promocao`}
          colorB={corSecundary}
          Icon={RiWaterPercentFill}
        />
        <LinkButton
          title="Pedido"
          link={`/${nomeEmpresa}/pedidos`}
          colorB={corSecundary}
          Icon={FaClipboardList}
        />
        <LinkButton
          title="Opções"
          link={`/${nomeEmpresa}/opcoes`}
          colorB={corSecundary}
          Icon={IoMenu}
        />
      </Flex>
      <Link to={`/${nomeEmpresa}/carrinho`}>
        <Flex
          boxShadow="2xl"
          position="absolute"
          bottom="65px"
          right="10px"
          p="10px"
          borderRadius="full"
          bg={corPrimary}
          _hover={{
            transition: "transform .2s",
            transform: "scale(1.1)",
            boxShadow: "lg",
          }}
        >
          <Flex position="relative">
            <HiMiniShoppingCart size="22" color={corSecundary} />
            <Flex
              minW="20px"
              justify="center"
              bg="red"
              color="white"
              fontSize="11px"
              borderRadius="full"
              px="4px"
              py="2px"
              position="absolute"
              bottom="4"
              right="-18px"
            >
              {productsCart?.length}
            </Flex>
          </Flex>
        </Flex>
      </Link>
      {/* <Link to={`/${nomeEmp}/carrinho`}>
        <Flex
          // position="relative"
          // bottom="120px"
          // right="5px"
          // maxW="100px"
          bg={corPrimary}
          fontWeight="700"
          rounded="full"
          px="8px"
          py="8px"
          fontSize="16px"
          textAlign="center"
          color="black"
          align="center"
          gap="2"
        >
          <FaShoppingCart size="18" />
          <Flex>{productsCart?.length}</Flex>
        </Flex>
      </Link> */}
    </Container>
  );
}

export default MenuBar;

const toastErroDados = {
  title: "Empresa nao encontrada",
  description: "Verifique se o endereco digitado é mesmo o da empresa desejada",
  status: "warning",
  variant: "subtle",
  duration: 4000,
  isClosable: true,
};
