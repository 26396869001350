import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./styles/theme";
import { QueryClient, QueryClientProvider } from "react-query";

import { SIDataContext } from "./Context/SIContext";
import Loading from "./components/Loading";
import MenuBar from "./components/MenuBar";

const Router = React.lazy(() => import("./routers/Router"));
const queryClient = new QueryClient();

export const baseURL = "https://delivery.gruposi.com.br/";

export const token = {
  user: "sidelivery",
  senha: "Si%$#@!",
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <ChakraProvider theme={theme}>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <SIDataContext>
          <Suspense fallback={<Loading />}>
            <Router />
            <MenuBar />
          </Suspense>
        </SIDataContext>
      </BrowserRouter>
    </QueryClientProvider>
  </ChakraProvider>,
  // </React.StrictMode>,
);
