// theme.ts (Version 2 needs to be a tsx file, due to usage of StyleFunctions)
import { extendTheme } from "@chakra-ui/react";

// Version 1: Using objects
const theme = extendTheme({
  components: {
    Checkbox: {
      // can be Radio
      baseStyle: {
        label: {
          touchAction: "none",
          pointerEvents: "none",
        },
        container: {
          touchAction: "none",
        },
      },
    },
  },
  breakpoints: {
    sm: "320px",
    xsm: "500px",
    md: "800px",
    lg: "960px",
    xl: "1200px",
  },
  styles: {
    global: (props) => ({
      body: {
        fontFamily: "Roboto",
        bg: "#2E2E2E",
      },
    }),
  },
});

export default theme;
